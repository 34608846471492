<template>
  <p-container class="no-bg">  
    <template slot="left" v-if="showLeft">
      <a-tree
        :show-line="true"
        :default-expanded-keys="['0-0']"
        :tree-data="treeData"
      ></a-tree>
    </template>
    <template slot="toolbar">
      <!-- <a-button type="primary" @click="handleEdit">编辑</a-button>
      <a-button type="primary">Excel导出</a-button> -->
      <a-button type="primary" @click="addData">新增</a-button>
      <a-button type="primary" @click="handleremove()">删除</a-button>
      <!-- <a-button type="primary">批量分类梳理</a-button>
      <a-button type="primary">申请号批量查询</a-button>
      <a-button type="primary">启动评审</a-button> -->
    </template>
    
    <a-table
      bordered
      :data-source="dataSource"
      :columns="columns"
      size="middle"
      :row-selection="rowSelection"
      :loading="loading"
      :pagination="pagination"
    >
    <a
        href="javascript:;"
        @click="gjcShowModal(col)"
        slot="gjcAction"
        slot-scope="text, col"
        >编辑</a
      >
     
      
    </a-table>    
    <!--关键词-->
    <a-modal v-model="gjcVisible" title="关键词" @ok="handleOk">
        <div>
        <a-input-group>
          <a-row :gutter="20" :data-source="dataSource" >
            <a-col :span="30">
                <a-form-item label="">
                  <a-textarea v-model="gjcText" @input="autoTextarea" :disabled="flase" :span="30">
                  </a-textarea>
                </a-form-item>
              </a-col>
          </a-row>
          
       </a-input-group>
        </div>
      <div>
      </div>
    </a-modal>
    
  </p-container> 
</template>
<script>
import {getmbxxgjz,getgjzbj,removegjz, addZjkKeyword} from "../../../../../api/result"

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
    
  },
  onSelect(record, selected, selectedRows) {
    localStorage.removeItem("gjzArry")
    console.log(selectedRows);
    localStorage.setItem('gjzArry',JSON.stringify(selectedRows))
    this.$store.commit('updateCount',selectedRows)
    console.log(this.$store.state.count)
    
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
    localStorage.removeItem("gjzArry")
     localStorage.setItem('gjzArry',JSON.stringify(selectedRows))
     
  },
};
const columns = [
  {
    title: "",
    dataIndex: "edit",
    width: "10%",
    scopedSlots: { customRender: "gjcAction" },
  },
  {
    title: "关键词",
    dataIndex: "word",
  },
  {
    title: "一级领域",
    dataIndex: "word",
  },
  {
    title: "二级领域",
    dataIndex: "word",
  },
  
];
const dataSource = [
        {
          key: "0",
          edit: "编辑",
          topic: "关键词",
        },
        {
          key: "2",
          edit: "编辑",
          topic: "关键词",
        },
]
export default {
    name:'QuickSearch',
    data() {
      return {
        loading: false,
        treeData: [],
        rowSelection,
        dataSource,
        columns,
        gjcVisible:false,
        gjcText:'',
        selectedRowss:[],
        pagination: {
          
        },
        isAdd: false
      }
    },
    mounted(){
      getmbxxgjz()
      .then(res=>{
        console.log(res)
      
       this.dataSource=res.data
      })
      .catch((e) => {})
      .catch((e) => {});
       localStorage.removeItem("gjzArry")
    },
      computed: {
      type(){
          return this.$route.name.substr(this.$route.name.lastIndexOf("_")+1);
      },
      // showLeft(){
      //   return this.type!='all'
      // }
  },
  watch: {
    $route(to, from) {
      this.initPage();
    },
  },
  created() {
    this.initPage();
  },
    methods: {
      addData() {
        this.gjcVisible = true;
        this.isAdd = true
      },
      initPage() {
      //tree
      switch (this.type) {
        case "subject":
          this.treeData = subjectTreeData;
          break;
        case "device":
          this.treeData = deviceTreeData;
          break;
        default:
          break;
      }
      //clear searchbar

      //loadList
    },
      gjcShowModal(col) {
        this.gjcVisible = true;
        this.mdl = { ...col }
        console.log(this.mdl)
        this.gjcText=this.mdl.word

        this.isAdd = false
      },
      handleOk(e) {
        if (this.isAdd) {
          this.gjcVisible = false;
          addZjkKeyword({
            word: this.gjcText
          }).then(res => {
            this.dataSource=res.data
          })
        } else {
          this.gjcVisible = false;
          console.log(this.gjcText)
          getgjzbj({
            keyguid: this.mdl.keyguid,
            word: this.gjcText
          })
          .then(res=>{
            console.log(res)
            this.dataSource=res.data
          })
        }
      },
      handleremove(){
        // const removeArry=JSON.parse(localStorage.getItem("gjzArry"))
        // const arrRemove=[]
        // removeArry.forEach(item => {
        //   console.log(item.keyguid)
        //   arrRemove.push(item.keyguid)
        // });
        // console.log(JSON.stringify(arrRemove))
        removegjz(
         JSON.parse(localStorage.getItem("gjzArry"))
        )
        .then(res=>{
            this.dataSource=res.data
        })
        console.log(JSON.parse(localStorage.getItem("gjzArry")))
      }
    }
 
}
</script>

<style scoped>
</style>